import React from 'react'
import keomLogo from '../../assets/images/keom-logo.svg'
import poweredBy from '../../assets/images/powered-by-polygon.svg'
import discordIcon from '../../assets/images/Vectordiscord.svg'
import socialNetIcon from '../../assets/images/Vectorsocialnet.svg'
import telegramIcon from '../../assets/images/Vectortelegram.svg'
import twitterIcon from '../../assets/images/Vectortwitter.svg'

import './styles.css'
const navClass = ' hover-text-primary-purple cursor-pointer transition-all text-white'

const NavLink = ({ href, text }: { href: string; text: string }) => {
  return (
    <a
      style={{
        textDecoration: 'none',
      }}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <p
        style={{
          margin: 0,
        }}
        className={navClass}
      >
        {text}
      </p>
    </a>
  )
}

const Footer = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        maxWidth: 1600,
        padding: '12px 16px',
        paddingBottom: 30,
        margin: 'auto',
        marginTop: 60,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          justifyContent: 'space-between',
        }}
        className=" lg:flex-row"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            alignItems: 'start',
          }}
        >
          <img src={keomLogo} alt="" />
          <p
            style={{
              fontSize: '24px',
              margin: 0,
            }}
          >
            The <span className="gradient-text">AI-Enhanced</span> Suite of ZK Powered <br />
            Trading Experiences.
            <br />
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          className="gap-8"
        >
          <div
            style={{
              gap: 12,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <NavLink
              text="Research"
              href="https://assets.website-files.com/622a09bc809cd190f58b7b15/62bc2790e0fa1d51aef47a93_Market_Risk_OVIX.pdf"
            />
            <NavLink text="Documentation" href="https://docs.keom.io/keom-protocol-documentation/" />
          </div>
          <div className="flex gap-3 flex-col">
            <NavLink text="Media Kit" href="https://drive.google.com/drive/folders/1QaeU-LqLs_EXLYlwT-C4w1neyrYUkQQ2" />
            <NavLink text="FAQ" href="https://docs.keom.io/keom-protocol-documentation/support/faq" />
          </div>
        </div>
      </div>
      <div className="flex flex-col lg-flex-row justify-between items-start lg-items-center gap-2">
        <div className=" flex flex-col-reverse lg-flex-row items-start lg-items-center gap-4">
          <img
            src={poweredBy}
            onClick={() => window.open('https://polygon.technology/', '_blank')}
            alt=""
            className=" h-9 cursor-pointer transition-all duration-500"
          />
          <div className=" flex ">
            <a
              href="https://www.keom.io/terms-of-use"
              target="_blank"
              rel="noreferrer"
              className=" text-white text-sm p-4 py-1 hover-text-primary-purple cursor-pointer transition-all"
            >
              Terms of Use
            </a>
            <div className=" w-px bg-gray " />
            <a
              href="https://www.keom.io/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className=" text-white text-sm  p-4 py-1 hover-text-primary-purple cursor-pointer transition-all"
            >
              Privacy policy
            </a>
            <div className=" w-px bg-gray" />
            <a
              href="https://www.keom.io/cookie-policy"
              target="_blank"
              rel="noreferrer"
              className=" text-white text-sm  p-4 py-1 hover-text-primary-purple cursor-pointer transition-all"
            >
              Cookie policy
            </a>
          </div>
        </div>

        <div className="flex flex-col lg-flex-row gap-5">
          <p className="text-sm m-0">© 2023, KEOM Protocol. All rights reserved</p>
          <div className="flex gap-5">
            <a href="https://twitter.com/KeomProtocol" target="_blank" rel="noreferrer">
              <img src={twitterIcon} alt="" className="h-5 w-5" />
            </a>
            <a href="https://discord.com/invite/keomprotocol" target="_blank" rel="noreferrer">
              <img src={discordIcon} alt="" className="h-5 w-5" />
            </a>
            <a href="https://t.me/KEOMProtocol" target="_blank" rel="noreferrer">
              <img src={telegramIcon} alt="" className="h-5 w-5" />
            </a>
            <a href="https://keomprotocol.medium.com/" target="_blank" rel="noreferrer">
              <img src={socialNetIcon} alt="" className="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
