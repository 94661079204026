import { useEffect, useRef } from 'react'
import './index.css'
import { widget, ChartingLibraryWidgetOptions, LanguageCode, ResolutionString, Timezone } from '../../charting_library'
import * as React from 'react'
import Datafeed from './api'
import { ExtendedSlot } from 'state/slots/hooks'
import { useWeb3React } from '@web3-react/core'

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol']
  interval: ChartingLibraryWidgetOptions['interval']

  // BEWARE: no trailing slash is expected in feed URL
  libraryPath: ChartingLibraryWidgetOptions['library_path']
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']
  clientId: ChartingLibraryWidgetOptions['client_id']
  userId: ChartingLibraryWidgetOptions['user_id']
  fullscreen: ChartingLibraryWidgetOptions['fullscreen']
  autosize: ChartingLibraryWidgetOptions['autosize']
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides']
  container: ChartingLibraryWidgetOptions['container']
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp('[\\?&]lang=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode)
}

const defaultProps: Omit<ChartContainerProps, 'container'> = {
  symbol: 'BTC/USD',
  interval: '15' as ResolutionString,
  libraryPath: '/charting_library/',
  chartsStorageUrl: '',
  chartsStorageApiVersion: '1.1',
  clientId: 'keomMargin',
  userId: 'keomMargin',
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
}

export const TVChartContainer = ({ symbol, slotData }: { symbol: string; slotData: ExtendedSlot[] }) => {
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const [trigger, setTrigger] = React.useState(0)
  const lines = useRef<any[]>([])
  const tvWidgetRef = useRef<any>(null)

  const { account } = useWeb3React()

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: (symbol || defaultProps.symbol) as string,
      // BEWARE: no trailing slash is expected in feed URL
      // tslint:disable-next-line:no-any
      datafeed: Datafeed,
      interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'header_saveload'],
      enabled_features: ['study_templates'],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: 'dark',
      timezone: (Intl.DateTimeFormat().resolvedOptions().timeZone || '').replace('Calcutta', 'Kolkata') as Timezone,
      time_frames: [
        { text: '1y', resolution: '1D' as ResolutionString, description: '1 Year' },
        { text: '6m', resolution: '240' as ResolutionString, description: '6 Months' },
        { text: '3m', resolution: '240' as ResolutionString, description: '3 Months' },
        { text: '1m', resolution: '60' as ResolutionString, description: '1 Month' },
        { text: '5d', resolution: '15' as ResolutionString, description: '5 Days' },
        { text: '1d', resolution: '5' as ResolutionString, description: '1 Day' },
      ],
    }

    const tvWidget = new widget(widgetOptions)

    tvWidget.onChartReady(() => {
      tvWidgetRef.current = tvWidget
      setTrigger((trigger) => trigger + 1)
    })

    return () => {
      lines.current?.forEach((line) => line?.remove?.())
      lines.current = []
      tvWidgetRef.current = null
      tvWidget.remove()
    }
  }, [symbol])

  useEffect(() => {
    if (trigger === 0) return

    const relevantSlots = slotData.filter((slot) => {
      return slot.collateralSymbol.includes(symbol) && slot.closeTime === 0
    })

    relevantSlots.map((slot) => drawLineFor(slot))
  }, [trigger, account])

  const drawLineFor = (slot: ExtendedSlot) => {
    if (tvWidgetRef.current) {
      console.log('draw line for', slot)
      const chart = tvWidgetRef.current.activeChart()

      const { entryPrice, direction, liquidationPrice, collateralSymbol } = slot

      const entryColor = '#665EED'
      const liqColor = '#FFAA27'

      lines.current.push(
        chart
          .createPositionLine()
          .setPrice(entryPrice)
          .setLineColor(entryColor)
          .setBodyBorderColor(entryColor)
          .setBodyBackgroundColor(entryColor)
          .setBodyTextColor('rgb(255, 255, 255)')
          .setLineStyle(1)
          .setLineLength(10)
          .setQuantity('')
          .setBodyFont('10px Inter,sans-serif')
          .setText(`${collateralSymbol} ${direction} Entry`)
      )

      lines.current.push(
        chart
          .createPositionLine()
          .setPrice(liquidationPrice)
          .setLineColor(liqColor)
          .setBodyBorderColor(liqColor)
          .setBodyBackgroundColor(liqColor)
          .setBodyTextColor('rgb(0, 0, 0)')
          .setLineStyle(1)
          .setLineLength(10)
          .setQuantity('')
          .setBodyFont('10px Inter,sans-serif')
          .setText(`${collateralSymbol} ${direction} Liquidation`)
      )
    }
  }

  return <div ref={chartContainerRef} className={'TVChartContainer'} />
}
